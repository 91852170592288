import ScrollableChildContainer from '@/components/containers/ScrollableChildContainer';
import { NavigationDrawer } from '@/components/drawer/NavigationDrawer';
import Error404 from '@/components/errors/404';
import AppToolbar from '@/components/toolbars/AppToolbar';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { styled } from '@mui/material';

const StyledBox = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  '.after-toolbar': {
    component: 'main',
    flexGrow: 1,
    overflow: 'hidden',
  },
}));

export default function AppPage({
  children,
  hideNavDrawer = false,
  breadcrumbs,
}: {
  children: ReactNode;
  hideNavDrawer?: boolean;
  breadcrumbs?: ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary fallback={<Error404 />}>
      <Box display='flex'>
        {!hideNavDrawer && <NavigationDrawer />}
        <StyledBox>
          <AppToolbar breadcrumbs={breadcrumbs} />
          {/* this box is responsible for setting the responsive height leftover from after the toolbar */}
          <Box className='after-toolbar'>
            <ScrollableChildContainer>{children}</ScrollableChildContainer>
          </Box>
        </StyledBox>
      </Box>
    </Sentry.ErrorBoundary>
  );
}
