/**
 * A utils to centralize all formatting like timestamps, bytes, etc
 */

import { isFile, VaultNode } from '@/types/vault';
import bytes, { BytesOptions } from 'bytes';

export function formatBytes(size: number, options?: BytesOptions) {
  const defaultOptions = { decimalPlaces: 2 };
  return bytes(
    size,
    options ? { ...defaultOptions, ...options } : defaultOptions,
  );
}

export function getExtensionFromFileName(name: string) {
  const items = name.split('.');
  return items[items.length - 1];
}

export function formatPct(value: number) {
  return (value * 100).toFixed(2) + '%';
}

/*
 * takes the code param from a FirebaseError
 * and returns an error message that can be surfaced to the user.
 *
 * It is not exhaustive list of error codes as many codes signify internal-error.
 * A full list of firebase auth error codes can be found here:
 * https://firebase.google.com/docs/auth/admin/errors
 */
export function userFacingErrorFromFirebaseAuthCode(code: string): {
  error: string;
  redirect?: boolean;
} {
  switch (code) {
    case 'auth/invalid-email':
      return {
        error: `Email format is invalid. Please correct and try again.`,
      };
    case 'auth/invalid-password':
      return {
        error: `Password is incorrect.`,
      };
    case 'auth/user-not-found':
      return {
        error: `We don't have a record for your account. Did you mean to`,
        redirect: true,
      };
    case 'auth/email-already-exists':
      return {
        error: `We already have a record for your email. Did you mean to`,
        redirect: true,
      };
    default:
      return {
        error: `An unexpected error has occurred. Please try again.`,
      };
  }
}

export function escapeCharsForRegex(val: string) {
  return val.replace(/[.*+?^${}()[\]\\]/g, '\\$&');
}

export function getRenameDialogLabel(item: VaultNode | undefined) {
  if (item) {
    return `Rename ${isFile(item) ? 'File' : 'Folder'}`;
  }
  return 'Rename';
}

/**
 * Truncate a text with a middle ellipsis if
 * it's longer than an arbitrary length
 * @returns the text with ellipsis if needed
 */
export function trimStringWithMiddleEllipsis({
  text,
  //arbitrary length. Less messy than trying to calculate the real length
  maxLength = 50,
}: {
  text: string | undefined;
  maxLength?: number;
}) {
  if (!text) return '';
  if (text.length < maxLength) return text;

  const ellipsis = '...';
  const charsOnEitherSide = Math.floor(maxLength / 2) - ellipsis.length;
  return (
    text.slice(0, charsOnEitherSide) + ellipsis + text.slice(-charsOnEitherSide)
  );
}

const SPLITTER_REGEXP = new RegExp(/[ -]+/);
/**
 * Tries to take the first letter of the first 2 words of the text
 * If it can't, uses fallbacks.
 * @param text
 * @returns
 */
export function reduceTextToTwoLetters(text: string) {
  // no text -> empty TextAvatar
  // text is too short (should never happen for projects)
  if (text.length <= 1) {
    return text;
  }
  // split on spaces and hyphens
  const items = text.split(SPLITTER_REGEXP);
  if (items.length > 1) {
    // take the first letter of the first 2 items
    return items[0][0] + items[1][0];
  }
  // no separators, take the first 2 letters of the text
  return text.substring(0, 2);
}
