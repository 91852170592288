// Currently this isn't getting hit due to Firebase handling routing exceptions

import { WrappedLink } from '@/components/links/WrappedLink';
import { Button, Container, Stack, styled, Typography } from '@mui/material';
import Head from 'next/head';

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  height: '100vh',
}));

const Styled404Stack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  '.back-button': {
    marginTop: theme.spacing(2),
  },
  '.large-404': {
    color: theme.palette.primary[400],
    fontSize: '200px',
    // ~ matches line height with font size
    // to remove white space above text
    // and balance out the vertical centering
    lineHeight: '150px',
    paddingBottom: theme.spacing(3),
  },
}));

export enum BodyMessageOptions {
  NotFound = ' The resource requested could not be found on this server.',
  WorkflowRunNotFound = `Sorry, this workflow run does not exist or you do not have permission to access it.`,
  WorkflowNotFound = `Sorry, this workflow does not exist or you do not have permission to access it.`,
  Forbidden = `Sorry, you do not have access to this resource.`,
  Waitlist = `Join the waitlist, and we'll get back to you as soon as we can: `,
  ToolNotFound = `Sorry, this tool does not exist. Please return to the Toolbox Library to select one.`,
  ToolRunNotFound = `Sorry, this tool run does not exist or you do not have permission to access it.`,
}

const WAITLIST_URL = 'https://info.formbio.com/waitlist';

export default function NotFound({
  bodyMessage = BodyMessageOptions.NotFound,
}: {
  bodyMessage?: BodyMessageOptions;
}) {
  return (
    <StyledContainer>
      <Head>
        <title>Page Not Found - FormBio</title>
      </Head>
      <Styled404Stack>
        {/* special handling while creating an org is disabled */}
        {bodyMessage === BodyMessageOptions.Waitlist ? (
          <Typography variant='body1'>
            <>
              {bodyMessage}
              <a href={WAITLIST_URL} target='_blank' rel='noopener noreferrer'>
                {WAITLIST_URL}
              </a>
            </>
          </Typography>
        ) : (
          <>
            <Typography variant='h1' className='large-404'>
              404
            </Typography>
            <Typography variant='h2'>Page Not Found</Typography>
            <Typography variant='body1'>{bodyMessage}</Typography>
          </>
        )}
        <div className='back-button'>
          <WrappedLink href='/'>
            <Button variant='contained' color='primary'>
              Go to Homepage
            </Button>
          </WrappedLink>
        </div>
      </Styled404Stack>
    </StyledContainer>
  );
}
