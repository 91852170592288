import { inManagementPath } from '@/utils/routes';
import { featureFlagIds } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

/**
 * A hook to handle management related content.
 * Can be used to redirect if the flag is not enabled,
 * show/hide content based on the current route
 */
export default function useManagement() {
  const flags = useFlags();
  const router = useRouter();

  /**
   * True if the management flag is enabled
   */
  const managementEnabled: boolean = useMemo(
    () => !!flags[featureFlagIds.showManagementPages],
    [flags[featureFlagIds.showManagementPages]],
  );

  // PK TODO: Determine if the boolean below is still applicable, everything should be dependent on the flag
  // itself rather than the path
  /**
   * Some content is dependent on
   * - the flag being enabled
   * - being inside the management/... path
   */
  const appliesToManagementContent = useMemo(
    () => managementEnabled && inManagementPath(router.pathname),
    [managementEnabled, router.pathname],
  );

  return {
    managementEnabled,
    appliesToManagementContent,
  };
}
