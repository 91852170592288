import { reduceTextToTwoLetters } from '@/utils/formatting';
import { Avatar, SimplePaletteColorOptions, useTheme } from '@mui/material';

export enum AvatarSizes {
  small = '32px',
  medium = '40px',
  large = '48px',
  extraLarge = '80px',
}

/**
 * A decent fontSize should be 3/8th of the avatar size
 */
const AVATAR_FONT_SIZES = new Map<AvatarSizes, string>([
  [AvatarSizes.small, '12px'],
  [AvatarSizes.medium, '15px'],
  [AvatarSizes.large, '18px'],
]);

/**
 * Colors are calculated by summing the char code values of {text}.
 * Then that number is normalized into {index} to fit into {colors} length.
 * The background color is the light shade while the font is the dark shade.
 */
function colorFromText(
  text: string,
  colors: Partial<SimplePaletteColorOptions>[],
) {
  const sum = (text || ' ')
    .split('')
    .map(i => (i ? i.charCodeAt(0) : 0))
    .reduce((a, b) => a + b);
  const index = sum % colors.length;
  return { bgColor: colors[index].light, color: colors[index].dark };
}

export default function TextAvatar({
  text,
  uniqId,
  variant,
  size = AvatarSizes.small,
  border = false,
}: {
  text: string;
  // for people with the same name but 2 email addresses
  // this field will create a higher chance to have 2 distinct colors
  uniqId?: string;
  variant?: 'square' | 'circular' | 'rounded';
  size?: AvatarSizes;
  border?: boolean;
}) {
  const theme = useTheme();
  const initials = reduceTextToTwoLetters(text).toUpperCase();
  const avatarColor = colorFromText(
    uniqId || text,
    // an array of the possible colors
    theme.palette.tertiaryColors || [theme.palette.primary],
  );
  return (
    <Avatar
      variant={variant}
      sx={{
        width: size,
        height: size,
        fontSize: AVATAR_FONT_SIZES.get(size),
        bgcolor: avatarColor.bgColor,
        color: avatarColor.color,
        borderRadius: variant === 'square' ? '4px' : undefined,
        border: border ? `2px solid ${avatarColor.color}` : undefined,
      }}
      alt={text}
    >
      {initials}
    </Avatar>
  );
}
