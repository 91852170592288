import { useNavigationContext } from '@/context/navigationContext';
import {
  AccordionItem,
  NavigableItem,
  NavItemNode,
  NavListItemType,
} from '@/types/nav-item';
import { dependsOnProject, isNavItemInRoute } from '@/utils/routes';
import { Box, Collapse, List, ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import NavListItemContent from './NavListItemContent';
import NavListLinkItem from './NavListLinkItem';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  // the extra border aligns the items
  // vertically both when selected and not selected
  '&.MuiListItemButton-root': {
    borderStyle: 'solid',
    borderWidth: '0 0 0 2px',
    borderColor: 'transparent',
    borderRadius: 0,
  },
  // controls the distance between icon and label
  '.MuiListItemIcon-root': {
    minWidth: '40px',
  },
  // icon size
  '.MuiListItemIcon-root .MuiSvgIcon-root': {
    fontSize: '1.1rem',
  },
  // on item selected or hovered
  '&.MuiListItemButton-root.Mui-selected,:hover': {
    backgroundColor: theme.palette.primary[800],
    borderColor: theme.palette.primary[800],
  },
  // on item selected: faint border + thicker secondary border on left side
  '&.MuiListItemButton-root.Mui-selected': {
    borderColor: theme.palette.primary[700],
    borderLeftColor: theme.palette.secondary.main,
    // these 2 box-shadow achieve a squared join of the left and top/bottom borders
    boxShadow: `inset 0px 1px 0px 0px ${theme.palette.primary[700]}, inset 0px -1px 0px 0px ${theme.palette.primary[700]}`,
  },
  '&.MuiListItemButton-root.leaf': {
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  '&.leaf:hover': {
    backgroundColor: theme.palette.primary[800],
  },
}));

/**
 * A list of clickable items with a chevron
 * that handles the state of the current selection.
 * It can be used to display navigation items or list triggering
 * some action (like plot selection) via the selectionChangeHandler
 * @param {title, items, selectionChangeHandler}
 * @returns a list of clickable components with an optional chevron right justified
 */
export default function NavList({
  title = 'list',
  items,
}: {
  title?: string;
  items: NavItemNode[];
}) {
  const navigationParams = useNavigationContext();
  const router = useRouter();

  /**
   * On page load, if a child is selected, the state
   * of opened parents needs to be updated accordingly.
   */
  useEffect(() => {
    if (navigationParams.openedParentIds.size === 0) {
      items.forEach(i => {
        if (i.type === NavListItemType.Parent && isAChildSelected(i)) {
          toggleOpenChildren(i);
        }
      });
    }
  }, [items]);

  /**
   * Opens the accordion
   * @param item the parent item that is clicked on
   */
  function toggleOpenChildren(parent: AccordionItem) {
    if (!navigationParams.isOpen) {
      // special handling if drawer is closed
      if (!navigationParams.openedParentIds.has(parent.label)) {
        navigationParams.toggleParent(parent.label);
      }
    } else {
      // open or close normally if drawer is open
      navigationParams.toggleParent(parent.label);
    }
  }

  function isParentOpen(parent: AccordionItem) {
    return (
      navigationParams.isOpen &&
      navigationParams.openedParentIds.has(parent.label)
    );
  }

  /**
   * When the drawer is collapsed or the accordion is closed, a parent
   * may take a selected state if one of its children
   * is selected.
   * @param i the parent item
   * @returns true if the parent is selected
   */
  function isItemSelected(i: NavigableItem): boolean {
    return (
      (!!i.href.pathname &&
        isNavItemInRoute(router.pathname, i.href.pathname)) ||
      (!!i.altHref?.pathname && router.pathname === i.altHref.pathname)
    );
  }

  function isAChildSelected(parent: AccordionItem) {
    for (let i = 0; i < parent.children.length; i++) {
      if (isItemSelected(parent.children[i])) {
        return true;
      }
    }
    return false;
  }

  function handleLeavingProject(i: NavigableItem) {
    // if going to a route that's not under projects,
    // remove the selected project so that other
    // project dependent routes are disabled
    if (!dependsOnProject(i.queryKeys)) {
      navigationParams.setProjectName(undefined);
    }
  }

  /**
   * 3 types of elements:
   * - item has children: accordion behavior element and nav link children
   * - item has href: regular nav link
   * - default: button with manual handler
   */
  return (
    <List component='nav' aria-label={`${title} list`}>
      {items.map(i => {
        if (i.type === NavListItemType.Parent) {
          const selected = isAChildSelected(i);
          const parentIsOpen = isParentOpen(i);
          return (
            <Box key={i.label}>
              <StyledListItemButton
                selected={selected}
                onClick={_event => {
                  // open accordion for any children & expand the drawer
                  toggleOpenChildren(i);
                  if (!navigationParams.isOpen) {
                    navigationParams.toggleOpen();
                  }
                }}
              >
                <NavListItemContent
                  item={i}
                  open={parentIsOpen}
                  selected={selected}
                />
              </StyledListItemButton>
              <Collapse in={parentIsOpen} orientation='vertical'>
                {i.children.map(child => {
                  return (
                    <NavListLinkItem
                      key={child.label}
                      item={child}
                      nested={true}
                      onClick={() => handleLeavingProject(child)}
                      selected={isItemSelected(child)}
                    />
                  );
                })}
              </Collapse>
            </Box>
          );
        } else if (i.type === NavListItemType.Leaf) {
          return (
            <NavListLinkItem
              key={i.label}
              item={i}
              onClick={() => handleLeavingProject(i)}
              selected={isItemSelected(i)}
            />
          );
        }
      })}
    </List>
  );
}
