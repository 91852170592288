/**
 * App bar
 */

import { AppBar, Color, Divider, useTheme } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { ReactNode } from 'react';

export default function BasicToolbar({
  backgroundColor,
  children,
  position = 'static',
}: {
  backgroundColor: string | Partial<Color>;
  children?: ReactNode;
  position?: 'static' | 'fixed';
}) {
  const theme = useTheme();
  return (
    <>
      <AppBar
        position={position}
        elevation={0}
        sx={{
          backgroundColor,
          width: '100%',
          zIndex: position === 'fixed' ? theme.zIndex.drawer + 1 : undefined,
        }}
      >
        <Toolbar>{children}</Toolbar>
        <Divider />
      </AppBar>
      {/* ensures the page content is not under the toolbar */}
      {position === 'fixed' && <Toolbar />}
    </>
  );
}
