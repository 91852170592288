import { AvatarProps } from '@/types/user';
import { Stack, Typography, useTheme } from '@mui/material';
import AvatarPicture from './AvatarPicture';
import { AvatarSizes } from './TextAvatar';

export default function AvatarMenuHeader({ avatar }: { avatar: AvatarProps }) {
  const theme = useTheme();
  return (
    <Stack
      direction='column'
      spacing={1}
      sx={{
        backgroundColor: theme.palette.neutral[100],
        p: 2,
        maxWidth: '400px',
      }}
      justifyContent='center'
      alignItems='center'
    >
      <AvatarPicture avatar={avatar} size={AvatarSizes.large} border />
      <Typography variant='body2' textAlign='center'>
        {avatar.displayName}
      </Typography>
      <Typography variant='body3' textAlign='center'>
        {avatar.email}
      </Typography>
    </Stack>
  );
}
