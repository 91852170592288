import { Link, SxProps } from '@mui/material';
import RouterLink from 'next/link';
import React from 'react';

type Props = React.ComponentPropsWithoutRef<typeof RouterLink> & {
  sx?: SxProps;
  onClick?: () => void;
  className?: string;
};

export function WrappedLink({ children, onClick, sx, ...props }: Props) {
  return (
    <RouterLink passHref {...props}>
      <Link underline='none' color='inherit' sx={sx} onClick={onClick}>
        {children}
      </Link>
    </RouterLink>
  );
}
