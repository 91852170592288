import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';

// this box allows independent children to be scrollable. must be a direct parent
type ScrollableChildContainerProps = Omit<
  BoxProps,
  'height' | 'overflow' | 'display' | 'flexDirection'
> & { children?: ReactNode };
export default function ScrollableChildContainer({
  children,
  ...otherBoxProps
}: ScrollableChildContainerProps) {
  return (
    <Box
      height='100%'
      overflow='hidden'
      display='flex'
      flexDirection='column'
      {...otherBoxProps}
    >
      {children}
    </Box>
  );
}

// this box allows it to be scrollable inside the container above. must be direct child
type ScrollableChildProps = Omit<BoxProps, 'overflow'> & {
  children?: ReactNode;
};
export function ScrollableChild({
  children,
  ...otherBoxProps
}: ScrollableChildProps) {
  return (
    <Box overflow='auto' {...otherBoxProps}>
      {children}
    </Box>
  );
}
