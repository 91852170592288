import { DEFAULT_PADDING, HALF_PADDING } from '@/constants';
import { Box, Button, Stack, styled, Tooltip, Typography } from '@mui/material';
import { ChevronDownBackground } from '../icons';

const StyleButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export function ProjectOrgSelectMenuButton({
  title,
  label,
  tooltip,
  mini = false,
  handleMenuOpen,
}: {
  title: string | null;
  label: string;
  tooltip: string;
  mini?: boolean;
  handleMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
}) {
  return (
    <>
      <Box
        paddingX={mini ? 'inherit' : DEFAULT_PADDING}
        display='flex'
        justifyContent={mini ? 'center' : 'inherit'}
      >
        <Typography variant='caption1' color='navItemDefaultTextColor'>
          {label}
        </Typography>
      </Box>
      <Tooltip placement='right' title={tooltip}>
        <StyleButton onClick={handleMenuOpen} fullWidth variant='text'>
          <Stack
            direction='row'
            paddingX={mini ? 0 : DEFAULT_PADDING}
            padding={HALF_PADDING}
            justifyContent='space-between'
            width='100%'
            alignItems='center'
          >
            {title && (
              <Typography variant='h4' noWrap color='primary.200'>
                {title}
              </Typography>
            )}
            <Box color='navItemDefaultTextColor' display='flex'>
              <ChevronDownBackground />
            </Box>
          </Stack>
        </StyleButton>
      </Tooltip>
    </>
  );
}
