import { useAuth, useLogout } from '@/hooks/auth';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import { AvatarProps } from '@/types/user';
import { getQueryParams } from '@/utils/api-helpers';
import { getHref } from '@/utils/routes';
import { navRoutes, QueryParams } from '@/utils/routes';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Badge, Divider, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { featureFlagIds } from 'config';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { PopupState as PopupStateType } from 'material-ui-popup-state/core';
import { useRouter } from 'next/router';
import React from 'react';
import { CaretDownFilledBackground, GridViewIcon } from '../icons';
import AvatarMenuHeader from './AvatarMenuHeader';
import AvatarPicture from './AvatarPicture';
import { WrappedLink } from '../links/WrappedLink';

export default function AppAvatarMenu() {
  const { user } = useAuth();
  const router = useRouter();
  const logout = useLogout();
  const orgId = getQueryParams(router, QueryParams.orgId);
  const { flags } = useLaunchDarkly();
  const profileEnabled = flags[featureFlagIds.showProfilePage];

  const avatar: AvatarProps = {
    displayName: user?.displayName || 'display name',
    image: user?.photoURL || undefined,
    email: user?.email || undefined,
  };

  async function handleLogout() {
    await logout();
    router.push(navRoutes.login.pathname);
  }

  /**
   * If the user is in a page under organizations/
   * the link goes to that org management page.
   * Otherwise, it goes to the management root which picks the
   * first organization
   */
  function getManagementHref() {
    if (orgId) {
      return getHref(router, navRoutes.managementOrg, {
        [QueryParams.orgId]: orgId,
      });
    }
    return getHref(router, navRoutes.management);
  }

  return (
    <PopupState variant='popover' popupId='demo-popup-menu'>
      {(popupState: PopupStateType) => (
        <React.Fragment>
          <IconButton aria-label='Account options' {...bindTrigger(popupState)}>
            <AvatarPicture avatar={avatar} border />
            <Badge
              badgeContent={
                <CaretDownFilledBackground
                  fontSize='small'
                  sx={{
                    border: '2px solid white',
                    borderRadius: '4px',
                  }}
                />
              }
            />
          </IconButton>
          <Menu
            {...bindMenu(popupState)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            MenuListProps={{ sx: { pt: 0 } }}
            PaperProps={{ sx: { borderRadius: '6px' } }}
          >
            <AvatarMenuHeader avatar={avatar} />
            <Divider sx={{ mb: 1 }} />
            {/* My Profile Navigation Item */}
            {profileEnabled && (
              <WrappedLink href={getHref(router, navRoutes.myProfile)}>
                <MenuItem>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary='My Profile' />
                </MenuItem>
              </WrappedLink>
            )}
            {/* My Organizations Navigation Item */}
            <WrappedLink href={getManagementHref()}>
              <MenuItem>
                <ListItemIcon>
                  <GridViewIcon />
                </ListItemIcon>
                <ListItemText primary='My Organizations' />
              </MenuItem>
            </WrappedLink>

            <MenuItem
              onClick={() => {
                popupState.close;
                handleLogout();
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary='Log Out' />
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}
