import { NavigableItem } from '@/types/nav-item';
import { Link } from '@mui/material';
import RouterLink from 'next/link';
import { StyledListItemButton } from './NavList';
import NavListItemContent from './NavListItemContent';

export default function NavListLinkItem({
  selected = false,
  item,
  nested = false,
  onClick,
}: {
  selected?: boolean;
  item: NavigableItem;
  nested?: boolean;
  onClick: () => void;
}) {
  return (
    <RouterLink href={item.href || '#'} passHref>
      <Link underline='none'>
        <StyledListItemButton
          selected={selected}
          onClick={onClick}
          className={nested ? 'leaf' : undefined}
        >
          <NavListItemContent item={item} nested={nested} selected={selected} />
        </StyledListItemButton>
      </Link>
    </RouterLink>
  );
}
