/**
 * App bar
 */

import { useNavigationContext } from '@/context/navigationContext';
import useManagement from '@/hooks/useManagement';
import { getHref, navRoutes } from '@/utils/routes';
import { Box, Button, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import AppAvatarMenu from '../avatars/AppAvatarMenu';
import { ArrowUDownRight } from '../icons';
import { WrappedLink } from '../links/WrappedLink';
import BasicToolbar from './BasicToolbar';

export default function AppToolbar({
  breadcrumbs,
}: {
  breadcrumbs?: ReactNode;
}) {
  const router = useRouter();
  const theme = useTheme();
  const navigationParams = useNavigationContext();
  const { appliesToManagementContent } = useManagement();
  /**
   * Pages managing projects, users, etc
   * have a different color than
   * the rest of the app.
   */
  function getBarColor() {
    if (appliesToManagementContent) {
      return theme.palette.primary[100]!;
    }
    return 'white';
  }

  function getBackButtonTextColor() {
    return theme.palette.primary[900];
  }

  function findLastProjectPage() {
    return navigationParams.lastProjectUrl || getHref(router, navRoutes.home);
  }

  return (
    <>
      <BasicToolbar
        position={appliesToManagementContent ? 'fixed' : 'static'}
        backgroundColor={getBarColor()}
      >
        <Box display='flex' width='100%'>
          <Box alignItems='center' display='flex'>
            {breadcrumbs}
          </Box>
          {appliesToManagementContent && (
            <Box
              alignItems='center'
              display='flex'
              color={getBackButtonTextColor()}
            >
              <WrappedLink href={findLastProjectPage()}>
                <Button
                  sx={{ py: 0.5 }}
                  variant='outlined'
                  color='inherit'
                  startIcon={
                    <ArrowUDownRight color={getBackButtonTextColor()} />
                  }
                >
                  Go back to Project
                </Button>
              </WrappedLink>
            </Box>
          )}
          <Box marginLeft='auto'>
            <AppAvatarMenu />
          </Box>
        </Box>
      </BasicToolbar>
    </>
  );
}
