import { OrganizationSelect } from '@/components/inputs/OrganizationSelect';
import { ProjectSelect } from '@/components/inputs/ProjectSelect';
import NavList from '@/components/lists/NavList';
import NavListButtonItem from '@/components/lists/NavListButtonItem';
import {
  DEFAULT_PADDING,
  NAV_DRAWER_MINIED,
  NAV_DRAWER_WIDTH,
} from '@/constants';
import { useNavigationContext } from '@/context/navigationContext';
import { ProjectPermissions, useAccessControl } from '@/hooks/useAccessControl';
import useLaunchDarkly from '@/hooks/useLaunchDarkly';
import useManagement from '@/hooks/useManagement';
import {
  AccordionItem,
  NavigableItem,
  NavItemNode,
  NavListItemType,
} from '@/types/nav-item';
import { getQueryParams } from '@/utils/api-helpers';
import {
  getHref,
  inManagementPath,
  navRoutes,
  QueryParams,
} from '@/utils/routes';
import { useOrganizations, useProject } from '@formbio/api';
import {
  Box,
  CSSObject,
  Drawer,
  Link,
  styled,
  Theme,
  Toolbar,
} from '@mui/material';
import { featureFlagIds } from 'config';
import RouterLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import AppLogo from '../assets/AppLogo';
import {
  ArrowLineLeft,
  Chart,
  CubeIcon,
  FolderOutlinedIcon,
  FourSquares,
  GridViewIcon,
  PeopleIcon,
} from '../icons';
import ArrowLineRight from '../icons/SvgIcons/navigation/ArrowLineRight';

function getBackground(theme: Theme) {
  return {
    backgroundColor: theme.palette.primary[900],
    backgroundImage: `url('/images/nav-background.jpg')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: NAV_DRAWER_WIDTH,
    backgroundPositionY: 'max(100%, 550px)',
  };
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  ...getBackground(theme),
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: NAV_DRAWER_MINIED,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  ...getBackground(theme),
});

const MiniDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: NAV_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  ' .easterEgg': {
    visibility: 'hidden',
  },
  ':hover .easterEgg': {
    visibility: 'visible',
  },
}));

export function NavigationDrawer() {
  const router = useRouter();
  // NB: There isn't a specific permission to guard against viewing
  // File Manager, so we'll need to key off creation. Perhaps it's worthwhile to create
  // a separate permission but to discuss with BE members
  const orgId = router.query.orgId as string | undefined;
  const projectId = router.query.pid as string | undefined;
  const { data: project } = useProject(orgId, projectId);
  const { flags } = useLaunchDarkly();
  const canViewFileManager = useAccessControl(
    ProjectPermissions.STORAGE_CREATE,
    project,
  );
  const showToolbox = flags[featureFlagIds.enableToolbox];
  const navigationParams = useNavigationContext();
  const organizationsQuery = useOrganizations();

  const org = useMemo(() => {
    const orgId = getQueryParams(router, QueryParams.orgId);
    return organizationsQuery.data?.find(org => org.id === orgId);
  }, [router, organizationsQuery.data]);

  const { appliesToManagementContent } = useManagement();

  useEffect(() => {
    if (project && project.name !== navigationParams.projectName) {
      navigationParams.setProjectName(project.name);
    }
  }, [project, navigationParams.projectName]);

  useEffect(() => {
    if (org && org.name !== navigationParams.organizationName) {
      navigationParams.setOrganizationName(org.name);
    }
  }, [org?.name]);

  const userHome: NavigableItem = {
    label: 'Home',
    startIcon: <FourSquares />,
    type: NavListItemType.Leaf,
    href: getHref(router, navRoutes.home),
    ...navRoutes.home,
  };

  const projectHome: NavigableItem = {
    label: 'Home',
    startIcon: <FourSquares />,
    type: NavListItemType.Leaf,
    href: getHref(router, navRoutes.projectHome),
    ...navRoutes.projectHome,
  };

  const projectMembers: NavigableItem = {
    label: 'Members',
    startIcon: <PeopleIcon />,
    type: NavListItemType.Leaf,
    href: getHref(router, navRoutes.projectMembers),
    ...navRoutes.projectMembers,
  };

  const workflowsList: NavigableItem = {
    label: 'Launch',
    type: NavListItemType.Leaf,
    parentId: 'workflows_parent',
    href: getHref(router, navRoutes.workflowsList),
    ...navRoutes.workflowsList,
  };

  const workflowRuns: NavigableItem = {
    label: 'Activity',
    type: NavListItemType.Leaf,
    parentId: 'workflows_parent',
    href: getHref(router, navRoutes.workflowRuns),
    ...navRoutes.workflowRuns,
  };

  const toolbox: NavigableItem = {
    label: 'Library',
    type: NavListItemType.Leaf,
    parentId: 'form_toolbox',
    href: getHref(router, navRoutes.toolbox),
    ...navRoutes.toolbox,
  };

  const toolRuns: NavigableItem = {
    label: 'Results',
    type: NavListItemType.Leaf,
    parentId: 'form_toolbox',
    href: getHref(router, navRoutes.toolRuns),
    ...navRoutes.toolRuns,
  };

  const fileManager: NavigableItem = {
    label: 'Files',
    type: NavListItemType.Leaf,
    startIcon: <FolderOutlinedIcon />,
    href: getHref(router, navRoutes.vault),
    ...navRoutes.vault,
  };

  const workflowsAccordion: AccordionItem = {
    label: 'Workflows',
    type: NavListItemType.Parent,
    startIcon: <Chart />,
    children: [workflowsList, workflowRuns],
  };

  const toolBoxAccordion: AccordionItem = {
    label: 'Toolbox',
    type: NavListItemType.Parent,
    startIcon: <CubeIcon />,
    children: [toolbox, toolRuns],
  };

  const projectNavItems: (NavigableItem | AccordionItem)[] = showToolbox
    ? [projectHome, workflowsAccordion, toolBoxAccordion, projectMembers]
    : [projectHome, workflowsAccordion, projectMembers];

  const projectNavItemsWithFileManager: (NavigableItem | AccordionItem)[] =
    showToolbox
      ? [
          projectHome,
          fileManager,
          workflowsAccordion,
          toolBoxAccordion,
          projectMembers,
        ]
      : [projectHome, fileManager, workflowsAccordion, projectMembers];

  const manageProjects: NavigableItem = {
    label: 'Projects',
    startIcon: <GridViewIcon />,
    type: NavListItemType.Leaf,
    href: getHref(router, navRoutes.managementOrg),
    ...navRoutes.managementOrg,
  };

  const manageMembers: NavigableItem = {
    label: 'Members',
    startIcon: <PeopleIcon />,
    type: NavListItemType.Leaf,
    href: getHref(router, navRoutes.managementMembers),
    ...navRoutes.managementMembers,
  };

  const userHomeNavItems: (NavigableItem | AccordionItem)[] = [userHome];

  const managementNavItems: (NavigableItem | AccordionItem)[] = [
    manageProjects,
    manageMembers,
  ];

  /**
   * The list of items available for navigation
   * changes depending on the route.
   * most routes are project dependent so only
   * no project nav cases needs to be added here.
   */
  const navItems: NavItemNode[] = useMemo(() => {
    if (router.pathname === navRoutes.management.pathname) {
      return []; // this page is a redirect. It shouldn't have any nav items
    }
    if (inManagementPath(router.pathname)) {
      return managementNavItems;
    }
    const projId = getQueryParams(router, QueryParams.projId);
    if (projId && !canViewFileManager) {
      return projectNavItems;
    } else if (projId && canViewFileManager) {
      return projectNavItemsWithFileManager;
    }
    return userHomeNavItems;
  }, [router.pathname, canViewFileManager, showToolbox]);

  return (
    <MiniDrawer variant='permanent' open={navigationParams.isOpen}>
      {!appliesToManagementContent && (
        <Box paddingX={navigationParams.isOpen ? DEFAULT_PADDING : 0}>
          <RouterLink
            href={getHref(router, navRoutes.organization, {
              [QueryParams.orgId]: orgId,
            })}
            passHref
          >
            <Link
              underline='none'
              sx={{
                paddingY: '0.5em',
              }}
            >
              {/* keep logo aligned with toolbar and user icon */}
              <AppLogo
                minied={!navigationParams.isOpen}
                onClick={() => navigationParams.setProjectName(undefined)}
              />
            </Link>
          </RouterLink>
        </Box>
      )}
      <Box height='100%'>
        {appliesToManagementContent ? (
          <>
            <Toolbar sx={{ mb: 3 }} />
            <OrganizationSelect org={org} />
          </>
        ) : (
          <ProjectSelect />
        )}
        <NavList items={navItems} />
      </Box>
      {/* button at the bottom of the drawer */}
      <Box paddingBottom={DEFAULT_PADDING}>
        <NavListButtonItem
          item={{
            label: 'Collapse',
            startIcon: navigationParams.isOpen ? (
              <ArrowLineLeft />
            ) : (
              <ArrowLineRight />
            ),
          }}
          onClick={() => navigationParams.toggleOpen()}
        />
      </Box>
    </MiniDrawer>
  );
}
