import { ProjectOrgSelectMenuButton } from '@/components/buttons/ProjectOrgSelectMenuButton';
import { useNavigationContext } from '@/context/navigationContext';
import { getHref, navRoutes, QueryParams } from '@/utils/routes';
import { Organization, useOrganizations } from '@formbio/api';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import router from 'next/router';
import { useState } from 'react';
import TextAvatar from '../avatars/TextAvatar';
import { WrappedLink } from '../links/WrappedLink';
import { OrgProjectMenu } from './ProjectSelect';

export function OrganizationSelect({ org }: { org: Organization | undefined }) {
  const navigationParams = useNavigationContext();
  const organizationsQuery = useOrganizations();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClick(orgName: string) {
    navigationParams.setOrganizationName(orgName);
    setAnchorEl(null);
  }

  function getFullOrganizationOrPlaceHolderName() {
    return navigationParams.organizationName || 'Select Organization';
  }

  function getTitle() {
    if (navigationParams.isOpen) {
      return getFullOrganizationOrPlaceHolderName();
    }
    return navigationParams.organizationName
      ? navigationParams.organizationName.substring(0, 1)
      : null;
  }

  return (
    <>
      <ProjectOrgSelectMenuButton
        label={navigationParams.isOpen ? 'Organization' : 'Org'}
        mini={!navigationParams.isOpen}
        tooltip={getFullOrganizationOrPlaceHolderName()}
        title={getTitle()}
        handleMenuOpen={handleOpen}
      />
      <OrgProjectMenu
        open={open}
        id='project-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {/* other menu items */}
        {organizationsQuery.data?.map(item => (
          <WrappedLink
            key={item.id}
            href={getHref(router, navRoutes.managementOrg, {
              [QueryParams.orgId]: item.id,
            })}
          >
            <MenuItem
              onClick={() => handleClick(item.name)}
              selected={org?.id === item.id}
              sx={{ py: 0.5 }}
            >
              <ListItemIcon>
                <TextAvatar text={item.name} variant='square' />
              </ListItemIcon>
              <ListItemText sx={{ px: 2 }} primary={item.name} />
            </MenuItem>
          </WrappedLink>
        ))}
      </OrgProjectMenu>
    </>
  );
}
