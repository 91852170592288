import { NavListItem } from '@/types/nav-item';
import { StyledListItemButton } from './NavList';
import NavListItemContent from './NavListItemContent';

export default function NavListButtonItem({
  item,
  selected = false,
  onClick,
  className,
}: {
  item: NavListItem;
  selected?: boolean;
  onClick: () => void;
  className?: string;
}) {
  return (
    <StyledListItemButton
      onClick={onClick}
      selected={selected}
      className={className}
    >
      <NavListItemContent item={item} selected={selected} />
    </StyledListItemButton>
  );
}
