import { NavRoute } from '@/utils/routes';
import { ReactElement } from 'react';
import { UrlObject } from 'url';

export enum NavListItemType {
  Leaf,
  Parent,
}

export interface NavListItem {
  label: string;
  startIcon?: ReactElement;
  type?: NavListItemType;
}

export interface NavigableItem extends NavListItem, NavRoute {
  parentId?: string;
  href: UrlObject;
  //an alternate pathname that can be use for matching a nav item to another route (eg. default page for project matches .../workflow-runs/ and .../[projId]/)
  altHref?: UrlObject;
  type: NavListItemType.Leaf;
}
export interface AccordionItem extends NavListItem {
  children: NavigableItem[];
  type: NavListItemType.Parent;
}

export type NavItemNode = NavigableItem | AccordionItem;
