import { ProjectOrgSelectMenuButton } from '@/components/buttons/ProjectOrgSelectMenuButton';
import { useNavigationContext } from '@/context/navigationContext';
import { useProjects } from '@formbio/api';
import { getHref, navRoutes, QueryParams } from '@/utils/routes';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { getQueryParams } from '@/utils/api-helpers';
import { sortBy } from 'lodash';
import { WrappedLink } from '../links/WrappedLink';
import TextAvatar from '../avatars/TextAvatar';

const ALL_PROJECTS_TITLE = 'View My Projects';

export const OrgProjectMenu = styled(Menu)(({ theme }) => ({
  '.MuiMenu-paper': {
    backgroundColor: theme.palette.primary[800],
    padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
  },
  '.MuiMenuItem-root': {
    borderRadius: '4px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  '.MuiMenuItem-root:hover, .MuiMenuItem-root.Mui-selected': {
    backgroundColor: theme.palette.primary[600],
  },
  '.MuiMenuItem-root:hover, .MuiMenuItem-root.Mui-selected.MuiMenuItem-root:hover':
    {
      backgroundColor: theme.palette.primary[500],
    },
  '.MuiTypography-root': {
    color: theme.palette.navItemSelectedTextColor,
  },
}));

export function ProjectSelect() {
  const navigationParams = useNavigationContext();
  const projectsQuery = useProjects();
  const theme = useTheme();
  const router = useRouter();
  const orgId = getQueryParams(router, QueryParams.orgId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const sortedProjects = useMemo(() => {
    return sortBy(projectsQuery.data, proj => proj.name.toLowerCase());
  }, [projectsQuery.data]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClick(projectName: string) {
    navigationParams.setProjectName(projectName);
    setAnchorEl(null);
  }

  function getFullProjectOrPlaceHolderName() {
    return navigationParams.projectName || 'Select Project';
  }

  function getTitle() {
    if (navigationParams.isOpen) {
      return getFullProjectOrPlaceHolderName();
    }
    return navigationParams.projectName
      ? navigationParams.projectName.substring(0, 1)
      : null;
  }

  function handleLeavingProject() {
    // if going to a route that's not under projects,
    // remove the selected project so that other
    // project dependent routes are disabled
    navigationParams.setProjectName(undefined);
  }

  return (
    <>
      <ProjectOrgSelectMenuButton
        label='Project'
        mini={!navigationParams.isOpen}
        tooltip={getFullProjectOrPlaceHolderName()}
        title={getTitle()}
        handleMenuOpen={handleOpen}
      />
      <OrgProjectMenu
        open={open}
        id='project-menu'
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {/* All Projects menu item */}
        <WrappedLink
          href={getHref(router, navRoutes.organization, {
            [QueryParams.orgId]: orgId,
          })}
        >
          <MenuItem
            onClick={handleLeavingProject}
            selected={!navigationParams.projectName}
            sx={{ py: 0.5 }}
          >
            <ListItemIcon>
              <TextAvatar text={ALL_PROJECTS_TITLE} variant='square' />
            </ListItemIcon>
            <ListItemText sx={{ px: 2 }} primary={ALL_PROJECTS_TITLE} />
          </MenuItem>
        </WrappedLink>
        <Divider sx={{ my: 1 }} color={theme.palette.navItemDefaultTextColor} />
        {/* other menu items */}
        {sortedProjects?.map(item => (
          <WrappedLink
            key={item.id}
            href={getHref(router, navRoutes.project, {
              [QueryParams.orgId]: item.organization.id,
              [QueryParams.projId]: item.id,
            })}
          >
            <MenuItem
              onClick={() => handleClick(item.name)}
              selected={navigationParams.projectName === item.name}
              sx={{ py: 0.5 }}
            >
              <ListItemIcon>
                <TextAvatar text={item.name} variant='square' />
              </ListItemIcon>
              <ListItemText
                sx={{ px: 2 }}
                primary={item.name}
                secondary={item.organization.name}
              />
            </MenuItem>
          </WrappedLink>
        ))}
      </OrgProjectMenu>
    </>
  );
}
