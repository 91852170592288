import { NavListItem, NavListItemType } from '@/types/nav-item';
import { Box, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ChevronRightIcon, ExpandLessIcon, ExpandMoreIcon } from '../icons';

export default function NavListItemContent({
  item,
  nested = false,
  selected = false,
  open = false,
}: {
  item: NavListItem;
  nested?: boolean;
  selected: boolean;
  open?: boolean;
}) {
  const color = selected ? 'navIconSelectedColor' : 'navItemDefaultTextColor';
  const textColor = selected
    ? 'navItemSelectedTextColor'
    : 'navItemDefaultTextColor';
  return (
    <>
      {item.startIcon && (
        <ListItemIcon sx={{ color }}>{item.startIcon}</ListItemIcon>
      )}
      {!item.startIcon && nested && (
        <ListItemIcon>
          <ChevronRightIcon sx={{ visibility: 'hidden' }} />
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography
          variant={nested ? 'body3' : 'caption1'}
          color={textColor}
          fontWeight={nested && selected ? 500 : undefined}
        >
          {item.label}
        </Typography>
      </ListItemText>

      {item.type === NavListItemType.Parent && (
        <Box color={textColor} display='flex'>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      )}
    </>
  );
}
