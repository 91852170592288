import { AvatarProps } from '@/types/user';
import { Avatar } from '@mui/material';
import TextAvatar, { AvatarSizes } from './TextAvatar';

export default function AvatarPicture({
  avatar,
  size = AvatarSizes.medium,
  border = false,
}: {
  avatar: AvatarProps;
  size?: AvatarSizes;
  border?: boolean;
}) {
  return (
    (avatar.image && (
      <Avatar
        alt={avatar.displayName}
        src={avatar.image}
        sx={{
          width: size,
          height: size,
        }}
      />
    )) || (
      <TextAvatar
        text={avatar.displayName}
        size={size}
        border={border}
        uniqId={avatar.email || avatar.displayName}
      ></TextAvatar>
    )
  );
}
