export default interface Vault {
  contents: VaultContents;
}
export interface VaultObject {
  createdAt: string;
  updatedAt: string;
  metadata: { [key: string]: string } | null;
  size: number;
  contentType: string;
  bucket: string;
  name: string;
}

type VaultNodeBase = {
  id: string;
  bucket: string;
  name: string;
  fullPath: string;
  updatedAt?: string;
  createdAt?: string;
  // User Id:
  createdBy?: string;
  type: VaultNodeType;
};

export enum VaultNodeType {
  File = 'File',
  Directory = 'Folder',
}

export type VaultNode = VaultFile | VaultDirectory;

export interface VaultFile extends VaultNodeBase {
  latestRunId?: string;
  filetype: string;
  mimetype?: string;
  type: VaultNodeType.File;
  size: number;
}

export interface VaultDirectory extends VaultNodeBase {
  contents: VaultContents;
  type: VaultNodeType.Directory;
}

export type VaultContents = { [key: string]: VaultFile | VaultDirectory };

export function isFile(node: VaultNode): node is VaultFile {
  return node.type === VaultNodeType.File;
}

export function isDirectory(node: VaultNode): node is VaultDirectory {
  return node.type === VaultNodeType.Directory;
}
